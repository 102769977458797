import { useState, useMemo } from 'react';
import { FilterIcon, AddIcon, SortIcon, RunIcon, SearchIcon, ShareIcon } from "../../icons";




//TestRunSelector Component
const TestRunSelector = ({ testRunId }) => {
  const [selectedTestRunId, setTestRunId] = useState(testRunId[0]);




  return (
      <select
          value={selectedTestRunId}
          onChange={(e) => setTestRunId(e.target.value)}
          className="bg-dashboard-dark-800 px-2 py-1.5 rounded-md border border-dashboard-dark-400 text-dashboard-text-gray"
      >
          {testRunId.map((id, index) => (
              <option key={index} value={id}>
                  {id}
              </option>
          ))}
      </select>
  );
};




//SearchBar Component
const SearchBar = ({ search, handleSearch }) => {
  return (
      <div className="relative border-l border-dashboard-dark-400 w-[40vw] text-secondary">
          <input
              value={search}
              onChange={(e) => handleSearch(e.target.value)}
              type="text"
              placeholder="Search Test Cases"
              className="bg-dashboard-dark-800 px-2 py-1.5 w-96 pl-10 w-full text-dashboard-text-gray focus:outline-none active:outline-none"
          />
          <SearchIcon className="absolute left-4 top-1/2 transform -translate-y-1/2 text-dashboard-text-gray w-4 h-4" />
      </div>
  );
};




//FilterButton Component
const FilterButton = () => {
  return (
      <FilterIcon className="text-dashboard-text-gray h-5 w-5 cursor-pointer" />
  );
};




//SortButton Component
const SortButton = () => {
  return (
      <SortIcon className="text-dashboard-text-gray w-5 h-5 cursor-pointer" />
  );
};




//AddTestCaseButton Component
const AddTestCaseButton = ({ onAddTestCase }) => {
  return (
      <button
          onClick={onAddTestCase}
          className="px-2 py-1.5 rounded-md border border-dashboard-dark-400 flex items-center space-x-2 text-dashboard-text-gray"
      >
          <AddIcon className="h-4 w-4" />
          <span>Add TestCase</span>
      </button>
  );
};




//ShareButton Component
const ShareButton = () => {
  return (
      <button className="px-2 py-1.5 rounded-md border border-dashboard-dark-400 flex items-center space-x-2 text-dashboard-text-gray">
          <ShareIcon className="h-4 w-4" />
          <span>Share</span>
      </button>
  );
};




//RunTestSuiteButton Component
const RunTestSuiteButton = ({ onRunTestSuite }) => {
  return (
      <button
          onClick={onRunTestSuite}
          className="px-2 py-1.5 rounded-md border border-[#E27AAB] bg-[#D85C93] flex items-center space-x-2"
      >
          <RunIcon className="w-4 h-4 text-white fill-current" />
          <span className="text-white">Run Test Suite</span>
      </button>
  );
};




const SelectAllButton = ({selectAll, setSelectAll}) => {
  return (
      <div className="flex items-center gap-3">
          <input
              // style={{ marginRight: "5px" }}
              type="checkbox"
              id="apiSelected"
              name="apiSelected"
              checked={selectAll}
              onClick={() => setSelectAll(!selectAll)}
          />
          <div>Select All</div>
      </div>
  )
}




//Control Components Mapping
const controlComponentMap = {
  SelectAllButton: () => <SelectAllButton />,
  SearchBar: (props) => (
      <SearchBar
      search={props.search}
      handleSearch={props.handleSearch}
      />
  ),
  FilterButton: () => <FilterButton />,
  SortButton: () => <SortButton />,
  TestRunSelector: (props) => (
      <TestRunSelector
          testRunId={props.testRunId}
      />
  ),
  AddTestCaseButton: (props) => (
      <AddTestCaseButton
          onAddTestCase={props.onAddTestCase}
      />
  ),
  ShareButton: () => <ShareButton />,
  RunTestSuiteButton: (props) => (
      <RunTestSuiteButton
          onRunTestSuite={props.onRunTestSuite}
      />
  ),
};




// Render multiple components based on the array of component types
const renderComponent = (filteredComponents) => {
  return filteredComponents.map((config, index) => {
      const Component = controlComponentMap[config.type];




      // If no matching component is found, return null
      if (!Component) {
          console.warn(`No component found for type: ${config.type}`);
          return null;
      }




      return <Component key={index} {...config.props} />
  }).filter(Boolean); // Remove any null components
};




//Control Component
export const Controls = ({ components }) => {
  const firstGroupComponents = useMemo(() =>
      components.filter((config) => config.group === "first"),
      [components]
  );




  const secondGroupComponents = useMemo(() =>
      components.filter((config) => config.group === "second"),
      [components]
  );




  return (
      <div className="sticky top-0 z-10"> {/* Make the header sticky */}
          <div className="px-4 py-1.5 border-b border-dashboard-dark-400 w-full">
              <div className="flex items-center justify-between">
                  {/* First Group of Components */}
                  <div className="flex items-center space-x-4">
                      {renderComponent(firstGroupComponents)}
                  </div>
                  {/* Second Group of Components */}
                  <div className="flex items-center space-x-4">
                      {renderComponent(secondGroupComponents)}
                  </div>
              </div>
          </div>
      </div>
  );
};
