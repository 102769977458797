import React, { useEffect, useMemo, useState } from "react";
import {
   addBaseURL
} from "../../Actions/tcActions";
import { useDispatch, useSelector } from "react-redux";
import { Dialog, Alert, Snackbar, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { MdOutlineArrowBackIos } from "react-icons/md";
import Layout from '../Pagelayout/Layout';
import { PiCopy } from "react-icons/pi";
import { LuTrash } from "react-icons/lu";
/*


IndividualTest component that renders the individual test case page.
It uses the useSelector and useDispatch hooks from react-redux to access the state and dispatch actions.
It uses the useEffect hook to fetch the test cases when the component mounts.
It renders the Sidebar component.
The Sidebar component displays the list of assertions for the selected API.
This component renders on the /test/:id route.


*/


const BaseURL = () => {
   const [data, setData] = useState(null)
   const [version, setVersion] = useState('Version-1.2.5')


   const { loading, baseUrls } = useSelector((state) => state.apis);


   const dispatch = useDispatch();
   const navigate = useNavigate();


   useEffect(() => {
       if (baseUrls && baseUrls.data) {
           setData([...baseUrls.data.urls, { source: '', baseUrl: '' }])
       }
   }, [baseUrls]);


   const handleDelete = (index) => {
       let temp = [...data]
       temp.splice(index, 1)
       // dispatch(addBaseURL(temp));
       setData(temp)
   }


   const handleUpdate = () => {
       let formattedData = data.filter((item) => item.source !== '' && item.baseUrl !== '')
       dispatch(addBaseURL(formattedData));
   }


   useEffect(() => {
       if (data && data[data.length-1].source.length > 0 && data[data.length-1].baseUrl.length > 0) {
           console.log('here', data)
           setData([...data, { source: '', baseUrl: '' }])
       }
   }, [data])


   const handleSourceChange = (value, ind) => {
       let temp = {...data[ind]}
       temp.source = value
       data[ind] = temp
       setData([...data])
   }


   const renderBaseUrls = () => {
       const arr = [];
       for (let i = 0; i < data?.length; i++) {
           arr.push(
               <div className="grid grid-cols-12 border-b border-[#2C2E33]">
                   <div className="col-span-1 flex items-center justify-center"><input
                       // style={{ marginRight: "5px" }}
                       type="checkbox"
                       id="apiSelected"
                       name="apiSelected"
                   /></div>
                   <input value={data[i].source} onChange={(e)=> handleSourceChange(e.target.value, i)} className="col-span-4 bg-inherit px-3 py-2 border-x border-[#2C2E33] focus:outline-none active:outline-none" />
                   <input value={data[i].baseUrl} onChange={(e)=> {
                       let temp = {...data[i]}
                       temp.baseUrl = e.target.value
                       data[i] = temp
                       setData([...data])
                   }} className="col-span-6 bg-inherit px-3 py-2 focus:outline-none active:outline-none" />
                   <div className="col-span-1 text-[#494949] cursor-pointer flex items-center justify-center border-l border-[#2C2E33] cursor-pointer" ><span onClick={() => handleDelete(i)} className="hover:bg-[#1D1E20] rounded-md p-1.5 hover:text-[#F87171]">
                       <LuTrash />
                   </span></div>
               </div>
           );
       }


       return arr;
   }


   return (
       <Layout>
           <div className="text-white h-screen overflow-y-scroll no-scrollbar flex w-full gap-4 p-6 text-[#B0B0B0]">
               <MdOutlineArrowBackIos className="cursor-pointer my-2" onClick={() => navigate(-1)} />
               <div className="w-full flex flex-col gap-6">
                   <div className="text-xl text-[#D9D9D9]">Version</div>
                   <div className="rounded-md p-3 border border-[#2C2E33] flex justify-between items-center w-1/2">
                       <input value={version} onChange={(e) => setVersion(e.target.value)} className="bg-inherit w-full focus:outline-none active:outline-none"/>
                       <PiCopy className="text-gray-400 cursor-pointer" />
                   </div>
                   <div className="rounded-md border border-[#2C2E33] text-[#A0A0A0] col-span-2 w-full">
                       <div className="border-b border-[#2C2E33] ">
                           <div className={"px-6 py-2 cursor-pointer border-b-2 border-[#E27AAB] text-[#D9D9D9] w-max"}>Base URLs</div>
                       </div>
                       <div className="max-h-[25vh] overflow-y-auto no-scrollbar">
                           {renderBaseUrls()}
                           {/* <div className="grid grid-cols-12 border-b border-[#2C2E33] h-[35px]">
                               <div className="col-span-1" />
                               <div className="col-span-4 px-3 py-2 border-x border-[#2C2E33]" />
                               <div className="col-span-6 px-3 py-2 border-r border-[#2C2E33]" />
                               <div className="col-span-1" />
                           </div> */}
                       </div>
                   </div>
                   <div onClick={handleUpdate} className="px-2 py-1.5 rounded-md border border-[#E27AAB] bg-[#D85C93] flex items-center self-end cursor-pointer">Update</div>
               </div>
           </div>


       </Layout >
   );
};


export default BaseURL;