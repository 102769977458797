import React, { useEffect, useState } from "react";
import "./IndividualAPI.css";
import {
    fetchAllTC,
    fetchIndividualAPI,
    updateAPI,
} from "../../Actions/tcActions";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Panel, PanelGroup } from 'react-resizable-panels';
import Layout from "../Pagelayout/Layout";
import { getMethodClasses } from "../../utils/ui";
import { MdOutlineArrowBackIos } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import JSONPretty from 'react-json-pretty';
import HeaderRow from "./HeaderRow/HeaderRow";
import { FiArrowUpRight } from "react-icons/fi";
import StopWatch from "../../icons/stopwatch.svg";
import Calendar from "../../icons/calendar.svg";
import { LiaClock } from "react-icons/lia";
import APIItem from "./APIItem/APIItem";


/*


IndividualAPI component that renders the individual API page of the application.
It uses the useSelector and useDispatch hooks from react-redux to access the state and dispatch actions.
It uses the useParams hook from react-router-dom to access the ID parameter.
It uses the useEffect hook to fetch the individual API and assertions when the component mounts.
It renders the ReqResBody component to display the request and response body of the API.
It renders the StatusBtn component to display the status of the API.
It renders the TypeBtn component to display the type of the API.
It renders the APISidebar component to display the sidebar with API details.


*/
const IndividualAPI = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [dependencies, setDependenies] = useState([])
    const [headers, setHeaders] = useState([]);
    const [toggleState, setToggleState] = useState(1);
    const [reqresToggle, setReqresToggle] = useState(1);
    const { id } = useParams();
    const [selectedInd, setSelectedInd] = useState({ show: true, val: id });
    const { apis, individualAPI, loading } = useSelector(
        (state) => state.apis
    ); // Load individual API from the state

    const { testCases } = useSelector(
        (state) => state.testCases
    ); // Load individual API from the state


    // Create a list of assertions to display
    let data = [];
    let apiPass = (individualAPI?.data?.assertions?.status?.pass && individualAPI?.data?.assertions?.status?.pass);

    if (apis) {
        apis.data?.forEach(api => {
            data.push(api.api); // Add the API to the list of assertions
        })
    }

    useEffect(() => {
        dispatch(fetchIndividualAPI(id)); // Fetch the individual API corresponding to the ID
        setSelectedInd({ show: true, val: id });
    }, [id]);


    let testCase = {}
    if (testCases) {
        testCase = testCases?.tests.filter(test => test.id === individualAPI?.data?.parentSuiteId)
    }
    useEffect(() => {
        if (individualAPI && individualAPI?.data) {
            dispatch(fetchAllTC(individualAPI?.data?.parentSuiteId))


            if (individualAPI?.data?.assertions?.request_headers) {
                Object.entries(individualAPI?.data?.assertions?.request_headers).forEach(([key, value]) => {
                    if (!headers.some(header => header[0] === key)) {
                        headers.push([key, value])
                    }
                });
            }
        }
    }, [individualAPI])

    useEffect(() => {
        if (apis && apis?.data) {
            const newDependencies = [...dependencies]; // Clone the current state to avoid mutation


            apis.data.forEach(api => {


                // Check if the dependency is from a previous step
                if (api?.api?.step < individualAPI?.data?.step) {
                    // Check if the dependency already exists
                    if (!newDependencies.some(dep => dep.title === api?.api?.title)) {
                        newDependencies.push(api.api); // Add new dependency
                    }
                }
            });


            // Only update state if new dependencies were added
            if (newDependencies.length !== dependencies.length) {
                setDependenies(newDependencies); // Update state with the new array
            }
        }
    }, [apis, individualAPI, dependencies]);

    let totalPass = 0;
    let totalFail = 2;
    if (
        individualAPI?.data?.assertions?.status.pass
    ) {
        totalPass += 1;
        totalFail -= 1;
    }
    if (
        individualAPI?.data?.assertions?.response.pass
    ) {
        totalPass += 1;
        totalFail -= 1;
    }

    const handleHeaderDelete = (ind) => {
        let tempArr = [...headers]
        tempArr.splice(ind, 1);
        let temp = {};
        tempArr.forEach(header => {
            if (header[0] !== "" && header[1] !== "") {
                temp[header[0]] = header[1];
            }
        });
        // console.log({ assertions: { ...individualAPI?.data?.assertions, request_headers: temp } });
        dispatch(updateAPI({ assertions: { ...individualAPI?.data?.assertions, request_headers: temp } }, id));
        setHeaders(tempArr)
    }


    const renderHeaders = () => {
        let headerArr = [];
        headers.map((header, index) => headerArr.push(<HeaderRow header={header} handleHeaderDelete={handleHeaderDelete} ind={index} />));
        return headerArr;
    }

    const renderToggleStateComps = (toggle) => {
        switch (toggle) {
            case 1:
                return <React.Fragment>
                    <div className="grid grid-cols-6 border-b border-[#2C2E33]">
                        <div className="border-r border-[#2C2E33] px-6 py-2">Assertion</div>
                        <div className="col-span-5 px-6 py-2 text-sm"><span className="px-4 bg-[#261322] rounded-full text-[#FF86BE]">02</span></div>
                    </div>
                    <div className="grid grid-cols-6 border-b border-[#2C2E33]">
                        <div className="border-r border-[#2C2E33] px-6 py-2">Passed</div>
                        <div className="col-span-5 px-6 py-2 text-sm"><span className="px-4 bg-[#112313] rounded-full text-[#65DC8D]">0{totalPass}</span></div>
                    </div>
                    <div className="grid grid-cols-6 border-b border-[#2C2E33]">
                        <div className="border-r border-[#2C2E33] px-6 py-2">Failed</div>
                        <div className="col-span-5 px-6 py-2 text-sm"><span className="px-4 rounded-full bg-[#240F10] text-[#F87171]">0{totalFail}</span></div>
                    </div>
                </React.Fragment>
            case 2:
                return <div className="max-h-[25vh] overflow-y-auto no-scrollbar">
                    <div className="grid grid-cols-12 border-b border-[#2C2E33]">
                        <div className="col-span-1" />
                        <div className="col-span-4 px-3 py-2 border-x border-[#2C2E33]">Key</div>
                        <div className="col-span-7 px-3 py-2">Value</div>
                    </div>
                    {renderHeaders()}
                </div>
            case 3:
                return <div className="max-h-[25vh] overflow-y-auto no-scrollbar">
                    <div className="grid grid-cols-10 border-b border-[#2C2E33]">
                        <div className="col-span-3 px-3 py-2 border-r border-[#2C2E33]">API Name</div>
                        <div className="col-span-7 px-3 py-2">End Point</div>
                    </div>
                    {dependencies.map((api, index) => <div className="grid grid-cols-10 border-b border-[#2C2E33]">
                        <div className="col-span-3 px-3 py-2">{api.title}</div>
                        <div className="col-span-6 px-3 py-2 border-x border-[#2C2E33]">{api.path}</div>
                        <div className="col-span-1 px-3 py-2 flex items-center justify-center hover:text-white" onClick={() => navigate(`/api/${api._id}`)}>
                            <span className="rounded-md p-1.5 hover:bg-[#1D1E20] cursor-pointer">
                                <FiArrowUpRight size={16} />
                            </span>
                        </div>
                    </div>)}
                </div>
            default:
                return "{}"
        }
    }

    const renderReqResBody = (toggle) => {
        switch (toggle) {
            case 1:
                return <JSONPretty
                    style={{
                        overflowY: 'auto',
                        height: "100%"
                    }}
                    id="json-pretty"
                    themeClassName='custom-json-pretty'
                    keyStyle='color: #E45799;'
                    data={individualAPI?.data?.assertions?.body ?? {}}
                />
            case 2:
                return <JSONPretty
                    style={{
                        overflowY: 'auto',
                        height: "100%"
                    }}
                    id="json-pretty"
                    themeClassName='custom-json-pretty'
                    keyStyle='color: #E45799;'
                    data={individualAPI?.data?.assertions?.response?.expected ?? {}}
                />
            case 3:
                return <JSONPretty
                    style={{
                        overflowY: 'auto',
                        height: "100%"
                    }}
                    id="json-pretty"
                    themeClassName='custom-json-pretty'
                    keyStyle='color: #E45799;'
                    data={individualAPI?.data?.assertions?.response?.actual ?? {}}
                />
            case 4:
                return <div>{individualAPI?.data?.assertions?.err?.message}</div>
            default:
                return "{}"
        }
    }

    return <Layout loading={loading}>
        {/* <Loader loading={loading} /> */}
        <div className="flex flex-col h-full">
            <PanelGroup direction="horizontal" className="flex-1 overflow-auto">
                <Panel minSize={20} className="overflow-auto pb-8 no-scrollbar border-r border-[#2C2E33]">
                    <div className="flex gap-6 text items-center mb-10 mt-6 p-3">
                        <MdOutlineArrowBackIos className="#B0B0B0 cursor-pointer" onClick={() => navigate(-1)} />
                        <span className="text-[#A0A0A0]">Test Case Details</span>
                    </div>
                    <div>
                        <div className="text-white px-3">Test Steps</div>
                        <div className="my-6 overflow-y-scroll max-h-[80vh] no-scrollbar">
                            {data?.length > 0 && data?.sort((a, b) => a.step - b.step).map((api, index) => <APIItem api={api} id={id} />)}
                        </div>
                    </div>
                </Panel>
                <Panel minSize={80} className="overflow-auto no-scrollbar px-4 py-6">
                    <div className="text-[#A0A0A0]">{individualAPI?.data?.title}</div>
                    <div className="flex gap-3 text-[#A0A0A0] my-4">
                        <div className="flex gap-2 rounded-md border border-[#282C33] px-2.5 py-1 items-center">
                            <img src={Calendar} alt='calendar' />
                            {testCase[0]?.date}
                        </div>
                        <div className="flex gap-2 rounded-md border border-[#282C33] px-2.5 py-1 items-center">
                            <LiaClock size={16} />
                            {testCase[0]?.time}
                        </div>
                        <div className="flex gap-2 rounded-md border border-[#282C33] px-2.5 py-1 items-center">
                            <img src={StopWatch} alt='stopwatch' />
                            {(testCase[0]?.duration) / 1000} secs
                        </div>
                    </div>
                    <div className="grid grid-cols-8 gap-4 my-4">
                        <div className="w-full rounded-md border border-[#2C2E33] py-4 flex items-center col-span-7">
                            <div className="px-4 border-r border-[#2C2E33]"><span className={"rounded-full px-4 py-1 text-xs " + getMethodClasses(individualAPI?.data?.method)}>{individualAPI?.data?.method}</span></div>
                            <div className="px-4">{individualAPI?.data?.path}</div>
                        </div>
                        <div className={"px-2.5 py-1 rounded flex gap-2 items-center justify-center col-span-1 " + (apiPass ? "bg-[#112313]" : "bg-[#240F10]")}>
                            <div className={"w-[10px] h-[10px] rounded-full " + (apiPass ? "bg-[#65DC8D]" : "bg-[#F87171]")} />
                            <span className={apiPass ? "text-[#DFF9E7]" : "text-[#FECACA]"}>{apiPass ? "Passed" : "Failed"}</span>
                        </div>
                    </div>
                    <div className="rounded-md border border-[#2C2E33] text-[#A0A0A0]">
                        <div className="flex justify-start border-b border-[#2C2E33] bg-[#0F1011]">
                            <div className={"px-6 py-2 cursor-pointer" + (toggleState === 1 ? " border-b-2 border-[#E27AAB] text-[#D9D9D9]" : "")} onClick={() => setToggleState(1)}>Stats</div>
                            <div className={"px-6 py-2 cursor-pointer" + (toggleState === 2 ? " border-b-2 border-[#E27AAB] text-[#D9D9D9]" : "")} onClick={() => setToggleState(2)}>Headers</div>
                            <div className={"px-6 py-2 cursor-pointer" + (toggleState === 3 ? " border-b-2 border-[#E27AAB] text-[#D9D9D9]" : "")} onClick={() => setToggleState(3)}>Dependency APIs</div>
                        </div>
                        {renderToggleStateComps(toggleState)}
                    </div>
                    <div className="flex justify-between mt-6 mb-4">
                        <div className="flex gap-3 items-center">
                            <div onClick={() => setReqresToggle(1)} className={"px-4 py-1 cursor-pointer rounded-md " + (reqresToggle === 1 ? "bg-[#1D1E20] text-white" : "border border-[#2C2E33] text-[#A0A0A0]")}>Request</div>
                            <div onClick={() => setReqresToggle(2)} className={"px-4 py-1 cursor-pointer rounded-md " + (reqresToggle === 2 ? "bg-[#1D1E20] text-white" : "border border-[#2C2E33] text-[#A0A0A0]")}>Expected Response</div>
                            <div onClick={() => setReqresToggle(3)} className={"px-4 py-1 cursor-pointer rounded-md " + (reqresToggle === 3 ? "bg-[#1D1E20] text-white" : "border border-[#2C2E33] text-[#A0A0A0]")}>Actual Response</div>
                            {(!individualAPI?.data?.pass && individualAPI?.data?.assertions?.err?.message) && <div onClick={() => setReqresToggle(4)} className={"px-4 py-1 cursor-pointer rounded-md " + (reqresToggle === 4 ? "bg-[#1D1E20] text-white" : "border border-[#2C2E33] text-[#A0A0A0]")}>Error Details</div>}
                        </div>
                        <div className="flex gap-3 items-center text-sm">
                            <div className="rounded-md px-4 py-1 border border-[#2C2E33] text-[#A0A0A0]">{individualAPI?.data?.duration / 1000} ms</div>
                            <div className={"px-4 py-1 rounded-md " + (individualAPI?.data?.assertions?.status?.actual < 300 ? "bg-[#112313] text-[#65DC8D]" : "bg-[#240F10] text-[#F87171]")}>{individualAPI?.data?.assertions?.status?.actual}</div>
                        </div>
                    </div>
                    <div className="rounded-md border border-[#2C2E33] p-3 max-h-[30vh] overflow-y-auto no-scrollbar">
                        {renderReqResBody(reqresToggle)}
                    </div>
                </Panel>
            </PanelGroup>
        </div>
    </Layout>
};


export default IndividualAPI;